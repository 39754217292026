// extracted by mini-css-extract-plugin
export var dNone = "project-detail-module--d-none--jHJnV";
export var dBlock = "project-detail-module--d-block--h3DpG";
export var dInline = "project-detail-module--d-inline--oNxcR";
export var dFlex = "project-detail-module--d-flex--uXCS2";
export var dMdNone = "project-detail-module--d-md-none--w8ZyT";
export var dMdBlock = "project-detail-module--d-md-block--PwDGD";
export var dMdFlex = "project-detail-module--d-md-flex--Uow2t";
export var dLgFlex = "project-detail-module--d-lg-flex--8pbnW";
export var dLgNone = "project-detail-module--d-lg-none--4vZyp";
export var dLgBlock = "project-detail-module--d-lg-block--jtJ1e";
export var dLgInline = "project-detail-module--d-lg-inline--h8Cdt";
export var projectDetail = "project-detail-module--projectDetail--e33vd";
export var info = "project-detail-module--info--eyA3r";
export var carouselWrapper = "project-detail-module--carouselWrapper--91xDW";
export var viewMore = "project-detail-module--viewMore--MUmYk";
export var description = "project-detail-module--description--DBYG2";
export var projectTitle = "project-detail-module--projectTitle--ojjXy";
export var content = "project-detail-module--content--WA4zO";
export var image = "project-detail-module--image--0Fboc";