import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import {
  activeItem,
  blockTransition,
  carousel,
  carouselImage,
  carouselItem,
  carouselOpen,
  carouselOverlay,
  closeButton,
  gallery,
  leftItem,
  nextButton,
  nextItem,
  prevButton,
  prevItem,
  rightItem,
} from "./image-carousel.module.scss";

import CarouselButton from "./carousel-button/carousel-button";
import ReactTouchEvents from "react-touch-events";

let closeBlockedTimeout;

const ImageCarousel = ({
  isOpen,
  close,
  images,
  galleryMode = false,
  alt = "carousel-image",
}) => {
  const offset = 2;
  // Add last two images to start
  const list = images.slice(-offset);
  list.push(...images);
  // Add first two images to end
  list.push(...images.slice(0, offset));
  const [activeIndex, setActiveIndex] = useState(offset);
  const [imageList] = useState(list);
  const [canTransition, setCanTransition] = useState(true);
  const [closeBlocked, setCloseBlocked] = useState(false);

  const onClose = (force = false) => {
    if (!force) {
      if (closeBlocked) {
        return;
      }
    }
    close();
  };

  const onItemClick = (event, index) => {
    if (event && index !== activeIndex) {
      event.stopPropagation();
    }
    changeItem(index);
  };

  const changeItem = (index) => {
    setCloseBlocked(true);
    if (closeBlockedTimeout) {
      clearTimeout(closeBlockedTimeout);
    }
    closeBlockedTimeout = setTimeout(() => setCloseBlocked(false), 500);
    // Those two conditions make the carousel infinite.
    // The image list has the last two items copied to
    // the start of it and the first two to the end (see above).
    // This has the effect that if one reaches the last image, the
    // first one will be the next to click on the right of it.
    // If clicked, index changes back to the start and animation happens.
    if (index > images.length + offset - 1) {
      setCanTransition(false);
      setActiveIndex(offset - 1);
      setTimeout(() => {
        setCanTransition(true);
        setActiveIndex(offset);
      }, 100);
      return;
    }
    if (index < offset) {
      setCanTransition(false);
      setActiveIndex(images.length + offset);
      setTimeout(() => {
        setCanTransition(true);
        setActiveIndex(images.length + offset - 1);
      }, 100);
      return;
    }
    setActiveIndex(index);
  };

  const getImageLoading = (index) => {
    if (index > activeIndex - 3 && index < activeIndex + 3) {
      return "eager";
    }
    return "lazy";
  };

  const getItemClass = (index) => {
    if (index === activeIndex) {
      return activeItem;
    }
    if (index === activeIndex - 1) {
      return prevItem;
    }
    if (index === activeIndex - 2) {
      return leftItem;
    }
    if (index === activeIndex + 1) {
      return nextItem;
    }
    if (index === activeIndex + 2) {
      return rightItem;
    }
    return "";
  };

  const onPrevItem = (event) => {
    if (event) {
      event.stopPropagation();
    }
    changeItem(activeIndex > 0 ? activeIndex - 1 : imageList.length - 1);
  };

  const onNextItem = (event) => {
    if (event) {
      event.stopPropagation();
    }
    changeItem(activeIndex < imageList.length - 1 ? activeIndex + 1 : 0);
  };

  const handleSwipe = (direction) => {
    if (direction === "left") {
      onNextItem();
    }
    if (direction === "right") {
      onPrevItem();
    }
  };

  return (
    <div
      className={`${carouselOverlay} ${galleryMode && gallery} ${
        isOpen ? carouselOpen : ""
      }`}
      onClick={() => onClose()}
    >
      {galleryMode && (
        <button className={closeButton} onClick={() => onClose(true)}>
          <span></span>
          <label>schließen</label>
        </button>
      )}
      <ReactTouchEvents onSwipe={(_, direction) => handleSwipe(direction)} onTa>
        <div className={carousel}>
          <CarouselButton
            onClick={(event) => onPrevItem(event)}
            color="white"
            className={prevButton}
          />
          {imageList.map((image, index) => (
            <div
              key={`${alt}-image-${index}`}
              className={`${carouselItem} ${getItemClass(index)} ${
                !canTransition && blockTransition
              }`}
              style={{
                transition: !canTransition && "unset",
              }}
              onClick={(event) => onItemClick(event, index)}
            >
              <GatsbyImage
                alt={alt}
                className={carouselImage}
                objectFit="contain"
                objectPosition="center"
                width="100%"
                height="100%"
                loading={getImageLoading(index)}
                image={getImage(image.localFile)}
              ></GatsbyImage>
            </div>
          ))}
          <CarouselButton
            direction="n"
            color="white"
            onClick={(event) => onNextItem(event)}
            className={nextButton}
          />
        </div>
      </ReactTouchEvents>
    </div>
  );
};

export default ImageCarousel;
