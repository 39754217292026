import { buttonPrev, carouselButton } from "./carousel-button.module.scss";

import React from "react";

const CarouselButton = ({ onClick, direction = "p", className }) => {
  return (
    <button
      onClick={onClick}
      className={[
        className,
        carouselButton,
        direction === "p" ? buttonPrev : null,
      ].join(" ")}
    >
      <span></span>
    </button>
  );
};

export default CarouselButton;
