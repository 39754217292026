import React, { useState } from "react";
import {
  carouselWrapper,
  content,
  description,
  info,
  projectDetail,
  projectTitle,
} from "./project-detail.module.scss";

import ImageCarousel from "../image-carousel/image-carousel";
import ReactMarkdown from "react-markdown";
import Seo from "../seo/seo";
import { format } from "date-fns";
import { graphql } from "gatsby";
import { stripHtml } from "string-strip-html";

const getLocation = (location) => {
  const parts = location.split("/");
  if (parts?.length > 1) {
    return parts[0].trim();
  }
  return location;
};

const ProjectDetail = ({ data }) => {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const project = data.strapiProjects;
  project.date = new Date(project.date);
  let seoDesc = project.description ?? "";
  let seoTitle = project.Title.toLowerCase();
  const seoLocation = getLocation(project.location).toLowerCase();
  let seoImage = null;
  if (project.images?.length > 0) {
    const image = project.images[0];
    seoImage = {
      src: image.url,
      width: image.width,
      height: image.height,
    };
  }
  if (seoDesc.length > 150) {
    seoDesc = stripHtml(project.description).result?.substring(0, 147) + "...";
  }
  console.log(seoLocation);
  if (!seoTitle.includes(seoLocation)) {
    seoTitle = `${project.Title} ${getLocation(project.location)}`;
  } else {
    seoTitle = project.Title;
  }

  const onOpenCarousel = () => {
    if (carouselOpen) {
      return;
    }
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    setCarouselOpen(true);
  };

  const onCloseCarousel = () => {
    if (!carouselOpen) {
      return;
    }
    document.body.style.overflow = "";
    document.body.style.position = "";
    setCarouselOpen(false);
  };

  return (
    <>
      <Seo
        description={seoDesc}
        title={seoTitle}
        image={seoImage}
        pathname={`projekte/${project.slug}`}
      />
      <div className={`${projectDetail} fade-in`}>
        <section className={info}>
          <h2 className={projectTitle}>{project.Title}</h2>
          <p>
            {project.location} / {format(project.date, "yyyy")}
          </p>
          <p>{project.info}</p>
        </section>
        <section className={content}>
          {project.images?.length > 0 && (
            <div className={carouselWrapper} onClick={onOpenCarousel}>
              <ImageCarousel
                galleryMode={carouselOpen}
                isOpen={carouselOpen}
                close={onCloseCarousel}
                images={project.images}
                alt={project.Title}
                onActiveChange={setCurrentImage}
              ></ImageCarousel>
            </div>
          )}
          <ReactMarkdown className={description}>
            {project.description}
          </ReactMarkdown>
        </section>
      </div>
    </>
  );
};

ProjectDetail.prevPath = "/projekte/";
export default ProjectDetail;

export const query = graphql`
  query ($slug: String!) {
    strapiProjects(slug: { eq: $slug }) {
      Title
      date
      description
      images {
        url
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, quality: 80)
          }
        }
      }
      info
      slug
      location
      year_only
    }
  }
`;
